class GlobalHeaderWarenkorb extends HTMLElement {
  async connectedCallback() {
    this.initEventListeners();
    await this.updateWarenkorbAnzahl();
  }

  initEventListeners() {
    window.addEventListener('warenkorb.update', () => { this.updateWarenkorbAnzahl(); });
    window.addEventListener('login.change', () => { this.ordneKundeZuUndUpdateWarenkorbAnzahl(); });
  }

  updateWarenkorbStatus(anzahlArtikel = 0) {
    this.querySelector('[interaction=link-warenkorb]').setAttribute("count", anzahlArtikel);
  }
  
  async ordneKundeZuUndUpdateWarenkorbAnzahl() {
    await fetch('/include/warenkorb/kundezuordnen', {method: 'GET', cache: "no-store"});
    this.updateWarenkorbAnzahl();
  }

  async updateWarenkorbAnzahl() {
    const anzahlArtikel = await getWarenkorbAnzahl();
    this.updateWarenkorbStatus(anzahlArtikel);
  }
}

if (!customElements.get("header-global-warenkorb")) {
  customElements.define("header-global-warenkorb", GlobalHeaderWarenkorb);
}

// Klick auf "Weiter einkaufen" Link auf der alten Step0-Seite führt zur ADS des ersten Produkt
window.addEventListener('click', (event) => {
  const button = event.target.closest('[component="step0-call-to-action"] a.back');
  if(!button) {
    return true;
  }
  const linkElement = document.querySelector('[component="step0-hinzugefügte-produkte"] a');
  if(!linkElement) {
    return true;
  }
  event.preventDefault();
  document.location = linkElement.getAttribute('href');

});

export {
  istArtikeldetailseite,
  istWarenkorbButtonDa,
  checkWarenkorbButton,
  sagSentryBescheid,
  getWarenkorbAnzahl
};

  async function getWarenkorbAnzahl() {
    try {
      const config = {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Accept': 'application/json'
        },
        credentials: 'include'
      };
      const response = await fetch('/api/warenkorb/size', config);
      const responseJson = await response.json();
      const anzahlArtikel = await responseJson.anzahlArtikel || "0";
      return anzahlArtikel;
    } catch (error) {
      return "0";
    }
  }

function checkWarenkorbButton() {
  const istAds = istArtikeldetailseite();
  if(istAds) {
    const istWkButtonDa = istWarenkorbButtonDa();
    if(istWkButtonDa === false) {
      sagSentryBescheid(); 
    }
  }
  return false;
}

function istArtikeldetailseite() {
  const artikelSidebar = document.querySelector('.artikel-sidebar');
  if(artikelSidebar) {
    return true;
  }
  return false;
}

function istWarenkorbButtonDa() {
  const warenkorbButton = document.querySelector('warenkorb-button .warenkorb-button, abo-preisbox');
  if(warenkorbButton) {
    return true;
  }
  return false;
}

function sagSentryBescheid() {
  const message = "Warenkorb-Button ist nicht auf ADS";
  const event = new CustomEvent("log-error", {
    bubbles: true,
    detail: {
          message
      }
  });
  document.dispatchEvent(event);
}

window.addEventListener("DOMContentLoaded", () => {
  checkWarenkorbButton();
});
